<template>
  <footer class="content container-fluid position-relative mb-4 px-lg-10">
    <div class="d-flex justify-content-center align-items-center gap-8 bottom-0 w-9">
      <i18n-t tag="p" keypath="footer" class="fs-6 mb-0">
        <template #icon>
          <BaseIcon :icon="['fas', 'heart']" />
        </template>
      </i18n-t>
    </div>
  </footer>
</template>
